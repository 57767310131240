<template>
    <div class="cp_collect_list_wrap">

        <!--리스트 형태-->
        <div class="cp_collect_list_box" v-for="competition in competitionData"
             :key="`competition-list-${competition.c_idx}`" v-show="listType === 'list'"
        >
            <div class="cp_collect_img_box" @click="moveDetail(competition.c_uuid)"
                 :style="`background-image : url(${returnImage(competition.Poster,278,370)})`"></div>
            <div class="cp_collect_info_box">
                <div class="cp_collect_info left" @click="moveDetail(competition.c_uuid)">
                    <div class="cp_collect_info_title_box">
                        <div class="cp_collect_badge_box">
                            <div class="cp_collect_badge"><span>{{ returnCategoryName(competition.cc_idx) }}</span>
                            </div>
                        </div>
                        <div class="cp_collect_second_line_wrap">
                            <div class="cp_collect_info_title">
                                <!--                            <div class="cp_collect_title">{{ competition.c_title }}</div>-->
                                {{ competition.c_title }}
                            </div>
                        </div>
                    </div>
                    <div class="cp_collect_hashtag">
                        <span v-for="(hash,index) in returnHashtag(competition.Hashtag)" :key="`hash-${index}`">
                            #{{ hash }}</span>
                    </div>
                    <div class="cp_collect_thumbnail_box">
                        <div class="cp_collect_thumbnail"
                             :style="`background-image : url(${returnImage(competition.Profile,32,32)})`"></div>
                        <div class="cp_collect_nick">{{ competition.mb_nick }}</div>
                    </div>
                </div>
                <div class="gray_bar" @click="moveDetail(competition.c_uuid)"></div>
                <div class="cp_collect_info right">
                    <div class="cp_collect_info_request_box" @click="moveDetail(competition.c_uuid)">
                        <div class="cp_collect_info_request_text" :class="{'eng':checkLang()}">{{ $t('entry') }}</div>
                        <div class="cp_collect_info_request_date">
                            <span>{{ returnApplyDate(competition) }}</span>
                        </div>
                    </div>
                    <div class="cp_collect_info_request_box" @click="moveDetail(competition.c_uuid)">
                        <div class="cp_collect_info_request_text" :class="{'eng':checkLang()}">{{
                                $t('presentation')
                            }}
                        </div>
                        <div class="cp_collect_info_request_date">
                            <span>{{ returnReleaseDate(competition.c_release_time) }}</span>
                        </div>
                    </div>
                    <div class="cp_collect_info_request_box box_mg" @click="moveDetail(competition.c_uuid)">
                        <div class="cp_collect_info_request_text box_mg_right" :class="{'eng':checkLang()}">
                            {{ $t('t_prize') }}
                        </div>
                        <div class="cp_collect_info_request_date box_mg_left">
                            <!--                            <div class="mingle_small_logo"></div>-->
                            <span class="mingle_price_wrap"><span
                                    class="mingle_price">{{ returnCommas(competition.c_prize) }}Mg</span></span><br/>
                            <span class="sub_span_w">(￦{{ returnCommas(returnMingle(competition.c_prize)) }})</span>
                        </div>
                    </div>
                    <div class="cp_collect_info_heart box_end">
                        <div class="heart_img" :class="{'active': isMyLike(competition)}"
                             @click="likeCompetition(competition.c_idx)"></div>
                        <div class="heart_count" @click="likeCompetition(competition.c_idx)">
                            {{ returnCommas(competition.likeCnt) }}
                        </div>
                        <div class="eyes_img" @click="moveDetail(competition.c_uuid)"></div>
                        <div class="eyes_count" @click="moveDetail(competition.c_uuid)">
                            {{ returnCommas(competition.c_hit) }}
                        </div>
                        <div class="cp_status" @click="moveDetail(competition.c_uuid)">
                            {{ returnStatus(competition.c_status) }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--썸네일 형태-->
        <div class="cp_collect_thumb_list_box">
            <div class="cp_collect_thumb_list" v-for="competition in competitionData"
                 :key="`competition-thumb-${competition.c_idx}`" v-show="listType === 'thumbnail'"
                 @click="moveDetail(competition.c_uuid)">
                <div class="cp_collect_thumb_img"
                     :style="`background-image : url(${returnImage(competition.Poster,278,384)})`">
<!--                    <div class="cp_collect_thumb_badge" :class="{'active' : competition.c_status === 3}">-->
<!--                        <competition-date-time :competition-data="competition"></competition-date-time>-->
<!--                    </div>-->
                  <div class="cp_category">
                    {{ returnCategoryName(competition.cc_idx) }}
                  </div>
                  <div class="cp_collect_info_box">
                    <div class="cp_collect_content">{{ competition.c_title }}</div>
                    <div class="cp_content_box">
                      <div class="cp_category_area">
                        <div class="cp_day">
<!--                          <competition-date-time :competition-data="competitionData[index]"></competition-date-time>-->
                          <competition-date-time :competition-data="competition"></competition-date-time>
                        </div>
                      </div>
                      <div class="cp_mingle_area">
                        <div class="cp_mingle_img"></div>
                        <div class="cp_mingle_price">{{returnCommas(competition.c_prize)}} Mg</div>
                      </div>
                      <div class="cp_won_price">(￦{{ returnCommas(returnMingle(competition.c_prize)) }})</div>
                    </div>
                  </div>
                </div>
<!--                <div class="cp_collect_thumb_type">-->
<!--                    <span>{{ returnCategoryName(competition.cc_idx) }}</span>-->
<!--                </div>-->
<!--                <div class="cp_collect_thumb_title">-->
<!--                    <span>{{ competition.c_title }}</span>-->
<!--                </div>-->
            </div>
        </div>
        <!--    <button @click="moveDetail(`${idx}`)">상세 페이지</button>-->
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import CompetitionCategoryMixin from "@/mixins/CompetitionCategoryMixin";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import priceMixins from "@/mixins/priceMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";


export default {
    name: "CompetitionListDefaultLayout",
    mixins: [imageOption, CompetitionCategoryMixin, priceMixins, imageResizeMixins],
    components: {CompetitionDateTime},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            listType: 'list',
            lang: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.lang = this.$localStorage.get('lang');
    },
    mounted() {
        EventBus.$on('changeListType', this.changeListType);
    },
    beforeDestroy() {
        EventBus.$off('changeListType');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        moveDetail(data) {
            this.$router.push(`/competition/${data}`)
        },
        changeListType(type) {
            this.listType = type;
        },
        /*returnCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },*/
        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY. MM. DD')
            let e_time = this.$moment(data.c_end_time).format('YYYY. MM. DD')
            return `${s_time} ~ ${e_time}`
        },
        returnReleaseDate(time) {
            let r_time = this.$moment(time).format('YYYY. MM. DD')
            return r_time;
        },
        returnProfile(profile) {
            if (!util.isEmpty(profile)) {
                return `${profile[0].org_url}?${this.setImageOptions(64, 64, this.returnExtension(profile[0].org_url))}`
            }
            return ''

        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, this.returnExtension(image[0].org_url))}`
            }
            return ''
        },
        returnStatus(status) {
            switch (status) {
                case 0 : {
                    return this.$t('scheduled_to_proceed')
                }
                case 1 : {
                    return this.$t('in_process2')
                }
                case 2 : {
                    return this.$t('under_review')
                }
                case 3 : {
                    return this.$t('p_completed')
                }
                default : {
                    return ''
                }
            }
        },

        returnHashtag(hashtag) {
            let list = []
            util.each(hashtag, (hash, index, arr) => {
                if (!util.isEmpty(hash.ic_name) && hash.ch_type === 1) {
                    list.push(hash.ic_name)
                }
                if (!util.isEmpty(hash.ch_hashtag) && hash.ch_type === 2) {
                    list.push(hash.ch_hashtag)
                }
            })
            return list
        },
        isMyLike(data) {
            return data.LikeStatus
        },
        likeCompetition(key) {
            EventBus.$emit('likeCompetition', key);
        },
        checkLang() {
            this.lang = this.$localStorage.get('lang');
            if (this.lang === 'en') {
                return true
            }
            return false
        },
        returnMingle(data){
          return `${(Number(data) * 10)}`
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
